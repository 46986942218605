<template>
  <div class="container">
    <CentralItemsContainer>
      <TeamItem v-for="team in arrayTeamsBySeason" :key="team.id" :team="team" @click="onTeamItemClicked" />
    </CentralItemsContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Teams',
  components: {
    CentralItemsContainer: () => import('@/components/Elements/CentralItemsContainer'),
    TeamItem: () => import('@/components/Elements/TeamItem'),
  },
  computed: {
    ...mapState('leagueGeneral', ['arrayTeamsBySeason', 'objectSeasonSelected']),
  },
  methods: {
    onTeamItemClicked(team) {
      if (this.$route.meta.to === 'players') {
        this.$router.push({
          name: 'players',
          params: {
            teamId: team.team_id,
          },
          query: {
            teamName: team.colloquial_name,
          },
        });
      } else {
        this.$router.push({
          name: 'teamProfile',
          query: {
            team: team.team_id,
            season: this.objectSeasonSelected.id,
            isLanding: true,
          },
        });
      }
    },
  },
  async mounted() {
    try {
      window.scrollTo(0, 0);
    } catch (e) {
      throw new Error(e);
    }
  },
};
</script>

<style>
.page-title {
  text-transform: uppercase;
}
</style>
